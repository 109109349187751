



















/* tslint:disable:no-console */
import { Component, Vue } from "vue-property-decorator";

import NavBar from "@/components/Nav-bar.vue";
import BlockBox from "@/components/common/Block-box.vue";
import CommentBlock from "@/components/block/Comment-block.vue";
import { getHomePageData } from "@/api";
import mixins from "@/mixin/index.ts";
import routerMixin from "@/mixin/router-mixin.ts";
import updateTdk from "@/utils/tdk";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "CommentaAppList",
  components: { NavBar, BlockBox, CommentBlock },
  mixins: [mixins, routerMixin]
})
export default class Comment extends Vue {
  private navs: any[] = [];
  private block: any[] = [];
  private pageMax: number = 20;
  private tdk(self: any) {
    return {
      title: `-${self.$route.query.source_block_name || '评论列表'}`,
    };
  }
  private serverPrefetch(): any {
    updateTdk(this);
  }
  private mounted(): void {
    const self: any = this;
    const ops = self.getPrevParams(null, "");
    self.navs.push({
      name: ops.block_name
    });
    self.getData();
  }
  private getData(more?: string) {
    const self: any = this;
    updateTdk(this);

    const rankId: number =
      Number(self.utils.getUrlParam("source_rank_id")) || 0;
    if (!more) { self.$doMotion(self, "LOADING_SWITCH", true)};
    return this.$store
      .dispatch("fetchCommentList", {
        context: this,
        rankId,
        params: { start: self.currIndex, max: self.pageMax }
      })
      .then((result: any) => {
        const block = result.value;
        if (block) {
          block.data_source = "commentAppList";
          if (self.block.data) {
            block.data = self.block.data.concat(block.data)
          }
          self.block = block
          self.dataMore = block.more;
          self.$doMotion(self, "LOADING_SWITCH", false);
          if (!more) {
            self.loadStatus1 = "ready" + self.currIndex;
          } else {
            self.loadStatus1 = "loaded" + self.currIndex;
          }
        }
      })
      .catch(err => {
        self.$toast.show(err);
        self.loadStatus1 = "end" + self.currIndex;
      });
  }
}
